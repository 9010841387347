import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { RegisterModel } from "./register/register.model";
import { AppConstants } from "../app-constants";
import { ResetPwdModel } from "./reset-pwd/reset-pwd.model";
import { Subject } from "rxjs";
import {ProspectModel} from "./prospect/prospect.model"
import {ApiResponseModel} from "./prospect/ApiResponse.model"
import {User} from './user.model'

@Injectable()
export class AuthService {
  loggedInMember: string = "";
  member_loggedIn_status_subject = new Subject<any>();
  emptyToken: string = "";
  apiKey_subject = new Subject();
  getmyinfo_subject = new Subject<User>();

  constructor(
    private appConstants: AppConstants,
    private router: Router,
    private http: HttpClient
  ) {}

  signup(registerModel: RegisterModel) {
    return this.http.post(this.appConstants.getSignInUrl(), registerModel);
  }

  getFAQs() {
    return this.http.get(this.appConstants.getFAQUrl());
  }

  addProspect(prospectModel: ProspectModel) {
    return this.http.post<ApiResponseModel>(this.appConstants.getAddProspectUrl(), prospectModel);
  }

  signin(username: string, password: string) {
    const httpOptions = {
      responseType: "json",
    };

    this.http
      .post(
        this.appConstants.getLoginUrl(),
        { username: username, password: password },
        { responseType: "json" }
      )
      .subscribe(
        (response) => {
          console.log("logging response" + response);
          this.storeToken(JSON.stringify(response));
          this.member_loggedIn_status_subject.next("success");
          //this.member_loggedIn_status_subject.complete();
        },
        (error) => {
          console.log(" logging error " + JSON.stringify(error.error));
          this.clearToken();
          this.member_loggedIn_status_subject.error({'code':error.error.httpCode,'message':error.error.message});

          //this.member_loggedIn_status_subject.complete();
        }
      );

    //return this.http.post(this.appConstants.getLoginUrl(), {username: memberNameOrEmail, password: password});
  }

  resetPwd(memberNameOrEmail: string) {
    const resetPwdParams = new URLSearchParams();
    resetPwdParams.append("usernameOrEmail", memberNameOrEmail);

    const httpOptions = {
      params: new HttpParams().append("usernameOrEmail", memberNameOrEmail),
    };

    console.log(" resetPwd " + this.appConstants.getResetPwdUrl());

    return this.http.get(
      this.appConstants.getResetPwdUrl() +
        "?usernameOrEmail=" +
        memberNameOrEmail
    );
  }

  resetPwdComplete(resetPwdModel: ResetPwdModel) {
    return this.http.post(
      this.appConstants.getResetPwdCompleteUrl(),
      resetPwdModel
    );
  }

  signout() {
    this.clearToken();
    this.loggedInMember = "";
    this.router.navigate(["home"]);
  }

  isAuthenticated(): boolean {
    let isAuthenticated = false;
    if (localStorage.getItem("token") === this.emptyToken) {
      isAuthenticated = false;
    } else if (!localStorage.getItem("token")) {
      isAuthenticated = false;
    } else {
      isAuthenticated = true;
    }
    //console.log('AuthService:isAuthenticated:returning ' + isAuthenticated);
    //console.log(' isAuthenticated token ---> ' + localStorage.getItem('token'));
    return isAuthenticated;
  }

  refreshAndStoreToken(): boolean {
    console.log(" AuthService refreshAndStoreToken enter  ");
    const token = "Bearer " + localStorage.getItem("token");
    console.log(" refreshAndStoreToken token" + token);

    const httpOptions = {
      headers: new HttpHeaders().append("Authorization", token),
    };

    this.http
      .get(this.appConstants.getRefreshTokenUrl(), httpOptions)
      .subscribe(
        (response) => {
          console.log("headers =======" + response);
          this.storeToken(JSON.stringify(response));
        },
        (error) => {
          console.log("could not refresh Token");
          this.clearToken();
          this.router.navigate(["/signin"]);
        }
      );

    return this.isAuthenticated();
  }

  getAuthenticatedMember(): string {
    console.log(
      " getAuthenticatedMember -->loggedInMemberName --> " +
        localStorage.getItem("loggedInMemberName")
    );

    if (localStorage.getItem("loggedInMemberName") === "null") {
      this.loggedInMember = "";
    } else {
      this.loggedInMember = localStorage.getItem("loggedInMemberName") || "";
    }
    return this.loggedInMember;
  }

  storeToken(response: any) {
    const responseJSON = JSON.parse(response);
    //const expiresAt = moment().add(response.exp, 'second');

    localStorage.setItem("token", responseJSON.token);
    //localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()) );
    localStorage.setItem("loggedInMemberName", responseJSON.memberName);
    //console.log(' token stored ' + responseJSON.token );
  }

  clearToken() {
    localStorage.setItem("token", this.emptyToken);
    localStorage.setItem("loggedInMemberName", this.emptyToken);
  }

  getAuthToken(): string {
    const token = "Bearer " + localStorage.getItem("token");
    return token;
  }

  genAPIKey() {
    const token = "Bearer " + localStorage.getItem("token");
    console.log(" genAPIKey token" + token);

    const headers = new HttpHeaders().append("Authorization", token);
    const httpOptions = {
      headers: headers,
      responseType: "text",
    };

    this.http
      .get(this.appConstants.genApiKeyUrl(), {
        headers,
        observe: "body",
        responseType: "text",
      })
      .subscribe(
        (data: string) => {
          this.apiKey_subject.next(data);
        },
        (error) => {
          this.apiKey_subject.error(error);
        }
      );
    //   .
    // this.http.get<string>(this.appConstants.genApiKeyUrl(), {headers},).subscribe(
    //     (data)=>{
    //       console.log(' auth servie genAPIKey'+data)
    //       this.apiKey_subject.next(data);
    //     },
    //     (error)=>{
    //       console.log(' auth servie genAPIKey error');
    //           console.log(error);
    //
    //       this.apiKey_subject.error(error);
    //     }
    // )
  }

  // getAuthHeaderOption(): RequestOptions {
  //   const token  =  'Bearer ' + localStorage.getItem('token');
  //   const header = new Headers({'Authorization' : token});
  //   const options = new RequestOptions({ headers: header });
  //   return options;
  // }
  //
  // getAuthHeaderOptionWithParams(params): RequestOptions {
  //   const token  =  'Bearer ' + localStorage.getItem('token');
  //   const header = new Headers({'Authorization' : token});
  //   const options = new RequestOptions({ headers: header, params: params });
  //   return options;
  // }

  getMyInfo():any  {
    console.log("inside get myInfo");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.getAuthToken());

    const url = this.appConstants.getMyInfo();

    console.log("url -->" + url);
    this.http.get<User>(url, { headers: headers }).subscribe(
        (response) => {
          this.getmyinfo_subject.next(response);
        },
        (error) => {
          this.getmyinfo_subject.next(error);
        }
    );

    //return this.http.get<User>(url, { headers: headers })
  }
}
