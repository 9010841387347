import { Component } from '@angular/core';

@Component({
  selector: 'app-summarizer-privacy-policy',
  templateUrl: './summarizer-privacy-policy.component.html',
  styleUrls: ['./summarizer-privacy-policy.component.css']
})
export class SummarizerPrivacyPolicyComponent {

}
